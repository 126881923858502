<template>
  <div>
    <input ref="fileInput" type="file" :accept="type.join(', ')" style="display: none;" @change="handleInput">
    <v-layout
      @click="file ? null : $refs.fileInput.click()"
      ref="dropArea"
      justify-center
      align-center
      :class="{ over : over, 'not-valid' : !isValid }"
      class="drop-area my-2">
      <v-fade-transition
        hide-on-leave 
        leave-absolute>
        <span v-show="!dropped">
          {{ !isValid ? 'Invalid file' : text }}
        </span>
      </v-fade-transition>
      <v-fade-transition
        hide-on-leave
        leave-absolute>
        <v-layout v-show="dropped" row wrap align-center justify-center>
          <v-icon :color="over ? 'white' : 'primary'" class="mr-1">mdi-insert-drive-file</v-icon>
          <p class="mb-0">{{ fileName }}</p>
          <v-btn v-if="!hidePreview" @click.stop.prevent="showPreview = true" class="ml-3 elevation-0" color="primary" fab small>
            <v-icon color="white">mdi-visibility</v-icon>
          </v-btn>
          <v-btn @click.stop.prevent="removeItem" class="ml-1 elevation-0" color="accent" fab small><v-icon color="white">mdi-close</v-icon></v-btn>
        </v-layout>
      </v-fade-transition>
    </v-layout>
    <v-dialog max-width="500px" v-if="!hidePreview && (existing || previewData) && showPreview" v-model="showPreview">
      <preview-csv
        v-if="existing && existing.value ? existing.type === 'csv' : file.type === 'text/csv'"
        :table="previewData"
        @close="showPreview = false" />
      <preview-image
        v-if="existing && existing.value ? existing.type === 'image' : ['image/jpeg', 'image/png'].includes(file.type)"
        :url="existing ? existing.value : undefined"
        :file="file"
        @close="showPreview = false" />
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: Object,
    },
    type: {
      type: Array,
      default: () => ['text/csv'],
    },
    text: {
      type: String,
      default: 'Drop file here or click to upload',
    },
    hidePreview: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    over: false,
    dropped: false,
    file: null,
    fileName: '',
    existing: null,
    previewData: null,
    showPreview: false,
    isValid: true,
  }),
  components: {
    previewCsv: () => import('@/components/modals/PreviewCSV'),
    previewImage: () => import('@/components/modals/PreviewImage'),
  },
  watch: {
    isValid(val) {
      if (!val) {
        setTimeout(() => {
          this.isValid = true;
        }, 1200);
      }
    },
  },
  mounted() {
    if (this.current && this.current.value) {
      this.dropped = true;
      this.fileName = this.current.value;
      this.existing = this.current;
    }
    const dropArea = this.$refs.dropArea;
    const prevent = e => e.preventDefault();
    window.addEventListener('dragstart', prevent);
    window.addEventListener('dragend', prevent);
    window.addEventListener('dragenter', prevent);
    window.addEventListener('dragleave', prevent);
    window.addEventListener('dragover', prevent);
    window.addEventListener('drag', prevent);
    window.addEventListener('drop', (e) => {
      e.preventDefault();
      if (!this.over) return;
      this.over = false;
      this.isValid = this.validateFile(e.dataTransfer.files);
      if (!this.isValid) {
        this.removeItem();
        return;
      }
      this.handleFile(e.dataTransfer.files);
    });
    dropArea.addEventListener('dragleave', () => {
      this.over = false;
      this.isValid = true;
    });
    dropArea.addEventListener('dragover', () => {
      this.over = true;
    });
  },
  // computed: {
  //   isValid() {
  //     if (!this.fileName) return true;
  //     if (this.fileName.length <= 3) {
  //       return false;
  //     }
  //     const ext = this.fileName.split('.').pop();
  //     return ext === 'csv';
  //   },
  // },
  methods: {
    removeItem() {
      this.over = false;
      this.dropped = false;
      this.fileName = '';
      this.file = null;
      this.previewData = null;
      this.existing = null;
      this.$emit('remove');
    },
    setPreview(file) {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        this.previewData = fileReader.result.split('\n').map(row => row.split(','));
      };
      fileReader.readAsText(file);
    },
    handleInput(e) {
      this.handleFile(e.target.files);
    },
    validateFile(e) {
      let type = e[0].type;
      if (!type) {
        const brokenName = e[0].name.split('.');
        type = brokenName[brokenName.length - 1];
        if (type === 'csv') {
          type = 'text/csv';
        } else {
          type = `image/${type}`;
        }
      }
      return this.type.includes(type);
    },
    handleFile(files) {
      const file = files[0];
      this.existing = null;
      this.fileName = file.name;
      this.dropped = true;
      this.setPreview(file);
      this.file = file;
      this.$emit('upload', file);
    },
  },
};
</script>

<style lang="scss" scoped>
  .drop-area{
    position: relative;
    width: 100%;
    min-height: 100px;
    padding: 14px;
    border: 2px dashed grey;
    border-radius: 8px;
    transition: all ease-in 225ms;
  }
  .over {
    background: #26a69a !important;
    color: #fff;
    border-color: #fff;
  }
  .not-valid {
    background: #e91e63 !important;
    color: #fff;
    border-color: #fff;
  }
</style>
